table{
  width:100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 4px;
  thead{
    tr{
      background-color: #22afde;
      th{
        font-family:'Helvetica Neue LT Ex Std';
        font-weight: 300;
        font-size: 12.3px;
        border:1px solid #949596;
        border-right:1px solid white;
        padding: 5px 5px;
        color:white;
        text-align: center;

        &:first-child{
          text-align: left;
          border-left:1px solid #949596;
          padding-left: 15px;
        }
        &:last-child{
          border-right:1px solid #949596;
          text-align: left;
          padding-left: 15px;
        }
        &.whiteTitle{
          background-color: #fff;
          border-top-color: white;
          border-left-color: white;
          border-right-color:#949596;
          font-size: 13.5px;
          color:black;
          padding-left: 0;
          font-weight: bold;
        }
      }
    }
  }
  tbody{
    tr{
      &:nth-child(even){
        background-color: #d2e9f6;
      }
      td{
        font-family:'Helvetica Neue LT Ex Std';
        font-weight: 300;
        font-size: 11px;
        padding: 5px 5px;
        border:1px solid #949596;
        text-align: center;
        vertical-align: top;
        &:first-child,&:last-child{
          text-align: left;
          padding-left: 15px;
        }
        &.thLike{
          background-color: #22afde;
          border-color: white;
          color:white
        }
      }
      th{
        font-family:'Helvetica Neue LT Ex Std';
        padding: 5px 5px;
        font-size: 11px;
        font-weight: 300;
        color:white;
        background-color: #22afde;
        border-bottom-color: white;
      }
    }
  }
}