.slicknav_menu {
  display:none;
}

@media #{$phone} {
  /* #menu is the original menu */
  .header__menu {
    display:none;
  }

  .slicknav_menu {
    display:block;
  }
}