.header {
  padding: 0  0 0 35px;
  min-height: 137px;
  display: flex;
  flex-direction: row;
  @media #{$phone}{
    flex-direction: column;
    min-height:unset;
  }
  &__logo {
    display: flex;
    flex-basis: 243px;
    @media #{$phone}{
      flex-basis: unset;
      padding: 20px 0;
    }
    justify-content: center;
    align-items: center;
    img {
      max-width: 243px;
      width:100%;
      height: auto;
    }
  }
  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: auto;

    display: flex;
    flex-direction: row;
    @media #{$phone}{
      flex-direction: column;
    }
    justify-content: flex-end;
    align-items: center;
    &__item {
      cursor: pointer;
      padding: 35px;
      @media #{$phone}{
        padding: 15px 0;
        text-align: center;
        border-bottom:1px solid #b1b3b4;
        width:100%;
        &:last-child{
          border-bottom: 0;
        }
      }
      a {
        text-transform: uppercase;
        font-family: 'helveticaneue';
        font-size: 12.7px;
        border-bottom: 0px solid #db0812;
        padding-bottom: 3px;
        &:hover,&.on{
          color:#db0812;
          border-bottom: 1px solid #db0812;
          padding-bottom:3px;
        }
      }
      &--lang {
        a {
          padding: 0 3px 3px 3px;
          color: #b1b3b4;
          &.active {
            border-bottom: 1px solid #b1b3b4;
          }
        }
      }
    }
  }
}