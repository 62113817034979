.dfr{
  display: flex;
  flex-direction: row;
}
.dfc{
  display: flex;
  flex-direction: column;
}
.dil{
  display: inline;
}