.Address{
  flex:auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  line-height: 24px;
  &__mail{
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
      margin-right: 10px;
    }
  }
  &.pl{
    padding-left: 60px;
  }
  &.mt{
    margin-top: 40px;
  }
}