.NewsContainer{
  max-width: 610px;
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  &__logo{
    min-width: 100px;
    @media #{$phone}{
      min-width: 80px;
      padding: 0 20px;
    }
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 30px 0 60px;
    padding-top: 40px;
    img {
      width: 80px;
      height: 80px;
      max-width: 100%;
      border-radius: 300px;
      border: 8px solid white;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
    }

  }
  &__list{
    display: flex;
    flex-direction: column;
    flex-basis: 480px;
    padding-top: 40px;
  }
}

.News{
  &__date{
    font-family: 'Helvetica Neue LT Ex Std';
    font-weight: 300;
    font-style: italic;
    display: inline;
    font-size: 13px;
    margin-right: 5px;
  }
  &__title{
    font-family: 'Helvetica Neue LT Ex Std';
    font-weight: 500;
    display: inline;
    font-size: 13px;
    line-height: 16px;
  }
  &__body{
    font-family: 'Helvetica Neue LT Ex Std';
    font-weight: 300;
    display: block;
    font-size: 13px;
    line-height: 16px;
  }
  margin-bottom: 20px;
}