.footer {
  display: flex;
  flex-direction: row;
  @media #{$phone}{
    flex-direction: column;
  }
  &__img {

  }
  &__left {
    flex-basis: 610px;
    padding-right: 20px;
    @media #{$phone}{
      flex-basis: unset;
      padding-right: 0;
    }
    &.grey{
      background-color: #e1e1e3;
    }
  }
  &__right {
    padding-left: 20px;
    flex-basis: 480px;
    @media #{$phone}{
      flex-basis: unset;
      padding-left: 0;
    }
    &.grey{
      background-color: #e1e1e3;
    }
  }
}

.address{
  padding-top: 40px;
  flex:auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  &__mail{
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
      margin-right: 10px;
    }
  }
}