#citterio-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width:100%;
  height: auto;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.gm-style-iw * {
  display: block;
  width: 100%;
}

.gm-style-iw h4, .gm-style-iw p {
  margin: 0;
  padding: 0;
}

.gm-style-iw a {
  color: #4272db;
}