.QualityFooter{
  display: flex;
  flex-direction: row;
  @media #{$phone}{
    flex-direction: column;
  }
  &__left{
    flex-basis: 610px;
    padding-left: 60px;
    @media #{$phone}{
      flex-basis: auto;
      padding-left: 0;
    }

    img{
      width:100%;
      max-width: 470px;
      margin-top: 40px;
      @media #{$phone}{
        width:calc(100% - 20px);
        padding: 0 10px;
      }
    }
    @media #{$phone}{
      flex-basis: unset;
    }
  }
  &__right{
    display: flex;
    flex-basis: 480px;
    @media #{$phone}{
      flex-basis: auto;
    }
    .address{
      padding-top: 40px;
      flex:auto;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      &__mail{
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
          margin-right: 10px;
        }
      }
    }
  }
}