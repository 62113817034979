html {
  font-family: 'Helvetica Neue LT Ex Std', Helvetica, sans-serif;
  font-size: 13.5px;
  letter-spacing: 0.5px;
  font-weight: 300;
  strong, b {
    font-family: 'Helvetica Neue LT Ex Std';
    font-weight: 500;
  }
}

a {
  text-decoration: none;
  color: black;
}
div{
  &.news{
    font-size: 13px;
  }
}

h1, h2, h3, big {
  font-family: 'Trajan Pro';
  font-weight: normal;
}

big {
  font-size: 18px;
  &::first-letter {
    font-size: 20px;
  }
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
}

.pageWrapper {
  max-width: 1090px;
  //min-width: 1090px;
  margin: 0 auto;
}

.page {
  padding: 0 15px;
  @media #{$phone}{
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: row;
  @media #{$phone}{
    flex-direction: column;
  }
  &__leftColumn {
    @media #{$phone}{
      margin-top: 20px;
    }
    min-height: 550px;
    flex-basis: 610px;
    @media #{$phone}{
      min-height: 400px;
      flex-basis: unset;
    }

    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    &__breadCrumbs {
      padding: 0 0 0 60px;
      min-height: 52px;
      background: linear-gradient(to right, #e1e1e3, white);
      display: flex;
      flex-direction: row;
      @media #{$phone}{
        padding: 20px;
        flex-direction: column;
      }
      &__breadcrumb {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: 12px;
        &:after {
          content: '>';
          margin: 0 10px;
        }
        &:last-child {
          text-decoration: underline;
          &:after {
            content: '';
            margin: 0 10px;
          }
        }
        &:hover{
          color:#db0812;
          &:after {
            color:black;
          }
        }
        &__active{
          font-size: 12px;
          display: flex;
          align-items: center;
          text-decoration: underline;
        }
        @media #{$phone}{
          margin-bottom: 10px;
        }
      }
    }
    &__content {
      padding: 30px 60px 0px 60px;
      p {
        margin-top: 0px;
        margin-bottom: 25px;
        line-height: 22px;
      }
      ul {
        padding-left: 20px;
        margin-bottom: 20px;
        li {
          margin-bottom: 5px;
        }
      }
      @media #{$phone}{
        padding: 15px;
      }
      &__header {
        margin-bottom: 20px;

        display: flex;
        flex-direction: row;
        &__icon {

          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          margin-right: 30px;
          img {
            max-width: 80px;
            max-height: 80px;
            border-radius: 300px;
            border: 8px solid white;
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
          }
        }
        &__title {

          display: flex;
          flex-direction: column;
          flex: 3;
          justify-content: center;
          &__mainTitle {
            font-family: 'Trajan Pro';
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 8px;
            text-transform: uppercase;
            &::first-letter {
              font-size: 35px;
            }
          }
          &__subTitle {
            font-size: 16px;
          }
        }
      }
      &__text {

      }
    }
    ul{
      li{
        margin-bottom: 20px;
        font-family: 'Helvetica Neue LT Ex Std';
        font-size: 13.5px;
      }
    }
  }
  &__rightColumn {
    @media #{$phone}{
      margin-top: 20px;
    }
    //background-color: #e1e1e3;
    display: flex;
    flex-basis: 480px;
    @media #{$phone}{
      flex-basis: unset;
      justify-content: center;
      align-items: center;
    }

    &__categories {
      background-color: #e1e1e3;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      &.home{
        justify-content: flex-start;
      }
      @media #{$phone}{
        padding: 20px 0;
      }
      &__separator {
        flex-basis: 2px;
        border-bottom: 2px solid white;
        margin: 0 60px;
      }
      &__category {
        flex-basis: 175px;
        max-height: 175px;
        display: flex;
        flex-direction: row;
        @media #{$phone}{
          padding: 20px 0;
        }
        &__image {
          min-width: 100px;
          @media #{$phone}{
            min-width: 80px;
            padding: 0 20px;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 30px;
          img {
            width: 80px;
            height: 80px;
            max-width: 100%;
            border-radius: 300px;
            border: 8px solid white;
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
          }
        }
        &__title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 2;
          @media #{$phone}{
            margin-right: 30px;
          }

          &__main {
            display: block;
            max-width: 100%;
            font-family: 'Trajan Pro';
            font-size: 20px;
            line-height: 30px;
            letter-spacing: .5px;
            &::first-letter {
              font-size: 23px;
            }
            @media #{$phone}{
              font-size: 18px;
            }
            text-transform: uppercase;
          }
          &__subtitle {
            p {
              margin: 0;
            }
            font-size: 13.5px;
          }
        }
        &__arrow {
          @media #{$phone}{
            display: none;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0.9;
          flex-direction: column;
          svg {
            width: 70px;
            height: 70px;
          }
        }
        &:hover {
          .content__rightColumn__categories__category__title__main,.content__rightColumn__categories__category__title__subtitle {
            color: #db0812;
          }
        }
      }
    }
    &__address{
      flex:auto;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13.5px;
      line-height: 24px;
      background: linear-gradient(#e1e1e3, #FFFFFF);
      &__mail{
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
          margin-right: 10px;
        }
      }
    }
    &__product {

      display: flex;
      flex-direction: column;
      &__header {

        display: flex;
        flex-direction: row;
        &__image {

          display: flex;
          flex-basis: 100px;
        }
        &__heading {

          display: flex;
          flex-direction: row;
          flex: auto;
          &__title {
            font-family: 'Trajan Pro';
          }
          &__subTitle {
          }
          &__subTitle2 {
          }
        }
      }
      &__content {
      }
    }
    > img {
      max-width: 480px;
      max-height: 550px;
      width: 100%;
      height: auto;
    }
  }
  &__singleColumn{
    &__breadCrumbs {
      padding-left: 60px;
      min-height: 52px;
      background: linear-gradient(to right, #e1e1e3, white);
      display: flex;
      flex-direction: row;
      @media #{$phone}{
        flex-direction: column;
      }
      &__breadcrumb {
        //line-height: 52px;
        display: flex;
        align-items: center;
        &:after {
          content: '>';
          margin: 0 10px;
        }
        &:last-child {
          &:after {
            content: '';
            margin: 0 10px;
          }
        }
        &:hover{
          color:#db0812;
          &:after {
            color:black;
          }
        }
        &__active{
          display: flex;
          align-items: center;
          text-decoration: underline;
        }
      }
    }
    &__content {
      padding: 30px 0 30px 60px;
      p {
        margin-top: 0px;
        margin-bottom: 25px;
        line-height: 22px;
      }
      ul {
        padding-left: 20px;
        margin-bottom: 20px;
        li {
          margin-bottom: 5px;
          font-family: 'Helvetica Neue LT Ex Std';
          font-size: 13.5px;


        }
      }
      @media #{$phone}{
        padding: 15px;
      }
      &__header {
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        &__icon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 30px;
          img {
            max-width: 80px;
            max-height: 80px;
            border-radius: 300px;
            border: 8px solid white;
            box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
          }
        }
        &__title {
          display: flex;
          flex-direction: column;
          flex: auto;
          justify-content: center;
          &__mainTitle {
            font-family: 'Trajan Pro';
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 8px;
            text-transform: uppercase;
            &::first-letter {
              font-size: 35px;
            }
          }
          &__subTitle {
            font-size: 16px;
          }
        }
      }
      &__text {

      }
    }
  }
  &__specs{
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
}
sup{
  font-family: 'helveticaneue';
  font-size: 70%;
}

.gradientBar{
  height: 55px;
  width:100%;
  background: linear-gradient(to right, #e1e1e3, #FFFFFF);
}
