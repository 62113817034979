.Iso {
  display: flex;
  flex-direction: row;
  &__icon {
    min-width: 100px;
    @media #{$phone}{
      min-width: 80px;
      padding: 0 20px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 0;
    img {
      width: 90px;
      height: 90px;
      max-width: 100%;
      border-radius: 300px;
      border: 5px solid white;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 12px;
  }
  &.mt {
    margin-top: 80px;
  }
  &.mb {
    margin-bottom: 40px;
  }
}