@font-face {
  font-family: 'helveticaneuebold';
  src: url('../fonts/helveticaneue_bold-webfont.woff2') format('woff2'),
  url('../fonts/helveticaneue_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'helveticaneue';
  src: url('../fonts/helveticaneue_light-webfont.woff2') format('woff2'),
  url('../fonts/helveticaneue_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Trajan Pro';
  src: url('../fonts/TrajanPro-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/TrajanPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/TrajanPro-Regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/TrajanPro-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/TrajanPro-Regular.svg#b8a70d5ec6d2a6627ed4d54bfa4580ba') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}
@font-face {
  font-family: 'Minion Pro';
  src: url('../fonts/Minion Pro.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Minion Pro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Minion Pro.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Minion Pro.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Minion Pro.svg#86de82e17b328cf35c1c65f89c072d6b') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

/* EXTENDED */



@font-face {
  font-family: 'Helvetica Neue LT Ex Std';
  src: url('../fonts/HelveticaNeueLTStd-LtExO.eot');
  src: url('../fonts/HelveticaNeueLTStd-LtExO.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeueLTStd-LtExO.woff2') format('woff2'),
  url('../fonts/HelveticaNeueLTStd-LtExO.woff') format('woff'),
  url('../fonts/HelveticaNeueLTStd-LtExO.ttf') format('truetype'),
  url('../fonts/HelveticaNeueLTStd-LtExO.svg#HelveticaNeueLTStd-LtExO') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue LT Ex Std';
  src: url('../fonts/HelveticaNeueLTStd-MdEx.eot');
  src: url('../fonts/HelveticaNeueLTStd-MdEx.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeueLTStd-MdEx.woff2') format('woff2'),
  url('../fonts/HelveticaNeueLTStd-MdEx.woff') format('woff'),
  url('../fonts/HelveticaNeueLTStd-MdEx.ttf') format('truetype'),
  url('../fonts/HelveticaNeueLTStd-MdEx.svg#HelveticaNeueLTStd-MdEx') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Ex Std';
  src: url('../fonts/HelveticaNeueLTStd-LtEx.eot');
  src: url('../fonts/HelveticaNeueLTStd-LtEx.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeueLTStd-LtEx.woff2') format('woff2'),
  url('../fonts/HelveticaNeueLTStd-LtEx.woff') format('woff'),
  url('../fonts/HelveticaNeueLTStd-LtEx.ttf') format('truetype'),
  url('../fonts/HelveticaNeueLTStd-LtEx.svg#HelveticaNeueLTStd-LtEx') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Ex Std';
  src: url('../fonts/HelveticaNeueLTStd-MdExO.eot');
  src: url('../fonts/HelveticaNeueLTStd-MdExO.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeueLTStd-MdExO.woff2') format('woff2'),
  url('../fonts/HelveticaNeueLTStd-MdExO.woff') format('woff'),
  url('../fonts/HelveticaNeueLTStd-MdExO.ttf') format('truetype'),
  url('../fonts/HelveticaNeueLTStd-MdExO.svg#HelveticaNeueLTStd-MdExO') format('svg');
  font-weight: 500;
  font-style: italic;
}


