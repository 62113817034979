.Admin__entities {
  width: 800px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  &__entity {
    flex: 1;
    justify-content: center;
    align-items: center;
    a {

      display: flex;
      flex-direction: column;
      ustify-content: center;
      align-items: center;
      text-decoration: none;
      i {
        font-size: 60px;
        text-align: center;
      }
      span {
        text-align: center;
      }
      &:hover {
        color: #db0812;
      }
    }
  }
}